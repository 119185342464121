.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.contact-bl {
		position: relative;
		padding: 0 0 0 25px;

		.fa {
			position: absolute;
			left: 0px;
			top: 6px;
		}
	}

}